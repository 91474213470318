<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Payment</h2>
    </template>
    <template #action-bar></template>
    <template #page-content>
      <r-card>
        <div class="column flex-wrap m-0">
          <b-table
            :data="billingDatas ? billingDatas : []"
            :loading="isLoading"
            paginated
            backend-pagination
            :total="meta.total"
            :per-page="paymentParams.perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            @cellclick="
              (row, column, rowIndex, columnIndex) =>
                quickView(row, columnIndex)
            "
            hoverable
          >
            <b-table-column field="no" label="No" v-slot="props">
              {{ props.index + 1 }}
            </b-table-column>
            <b-table-column field="packageName" label="Service" v-slot="props">
              {{ props.row.package.packageName }}
            </b-table-column>
            <b-table-column
              field="billingCycle"
              label="Billing Cycle"
              v-slot="props"
            >
              {{
                props.row.actualPayDate == null
                  ? '-'
                  : formatDate(props.row.actualPayDate)
              }}
            </b-table-column>
            <b-table-column
              field="billingCycle"
              label="Billing Date Issue"
              v-slot="props"
            >
              <span v-if="props.row.actualPayDate == null">-</span>
              <span v-else>
                {{ props.row.actualPayDate | moment }}
              </span>
            </b-table-column>
            <b-table-column field="amount" label="Amount" v-slot="props">
              Rp. {{ props.row.package.price | numeral }}
            </b-table-column>
            <b-table-column
              field="invoice"
              label="Invoice Number"
              v-slot="props"
            >
              {{ props.row.invoice }}
            </b-table-column>
            <b-table-column field="status" label="Status" v-slot="props">
              <b-tag
                rounded
                style="text-transform: capitalize"
                :type="paymentStatus(props.row.status)"
              >
                {{ props.row.status }}
              </b-tag>
            </b-table-column>
            <b-table-column
              field="actualPayDate"
              label="Actual Pay Date"
              v-slot="props"
            >
              <span v-if="props.row.actualPayDate == null">-</span>
              <span v-else>
                {{ props.row.actualPayDate | moment }}
              </span>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    No data yet, please contact us if you have any problems
                    (hello@getrise.id)
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </r-card>
      <router-view />
    </template>
    <template #page-sidebar>
      <b-sidebar position="static" right v-model="isOpen" :style="sideBarStyle">
        <span v-if="billingData">
          <div style="position: relative; padding: 10px">
            <div class="columns">
              <div class="column is-9"><b>Transaction Details</b></div>
              <div class="column is-3">
                <div class="is-pulled-right" @click="() => (isOpen = false)">
                  <b-icon
                    size="is-small"
                    icon="close"
                    style="cursor: pointer; text-align: right"
                  ></b-icon>
                </div>
              </div>
            </div>

            <div style="font-size: 11px; margin-top: 20px">
              <div class="columns">
                <div class="column is-12">
                  <h3 style="text-align: center; margin: 0">
                    {{ billingData.company.name }}
                  </h3>
                  <h4 style="text-align: center">
                    {{ billingData.invoice }}
                  </h4>
                  <div
                    style="text-align: center"
                    v-if="billingData.status === 'unpaid'"
                  >
                    <div style="color: red">Unpaid</div>
                  </div>
                  <div
                    style="text-align: center"
                    v-if="billingData.status === 'paid'"
                  >
                    <div style="color: green">Paid</div>
                  </div>
                </div>
              </div>

              <hr style="margin: 10px" />
              <b>Service</b>
              <div class="columns">
                <div class="column is-6">
                  <h5 v-if="billingData.packageId === 1">Package A</h5>
                  <p v-if="billingData.packageId === 1">
                    R Employee Management Project Management Academy max
                    {{ billingData.package.maxUsers }}
                    users
                  </p>
                  <h5 v-if="billingData.packageId === 2">Package B</h5>
                  <p v-if="billingData.packageId === 2">
                    R Employee Management Project Management Academy Max
                    {{ billingData.package.maxUsers }}
                    users
                  </p>
                  <h5 v-if="billingData.packageId === 3">Package C</h5>
                  <p v-if="billingData.packageId === 3">
                    R Employee Management Project Management Academy Max
                    {{ billingData.package.maxUsers }}
                    users
                  </p>
                  <h5 v-if="billingData.packageId === 4">Package D</h5>
                  <p v-if="billingData.packageId === 4">
                    R Employee Management Project Management Academy Max
                    {{ billingData.package.maxUsers }}
                    users
                  </p>
                  <h5 v-if="billingData.packageId === 5">Custom Package</h5>
                  <p v-if="billingData.packageId === 5">
                    R Employee Management Project Management Academy Max
                    {{ billingData.package.maxUsers }} users
                  </p>
                </div>
                <div class="column is-6 word-break">
                  <h5>Rp. {{ billingData.package.price | numeral }}</h5>
                </div>
              </div>
              <div class="columns" v-if="billingData.prorate > 0">
                <div class="column is-6">
                  <h5 style="margin: 0">Prorate</h5>
                </div>
                <div class="column is-6 word-break">
                  <h5 style="margin: 0">
                    Rp. -{{ billingData.prorate | numeral }}
                  </h5>
                </div>
              </div>
              <div
                class="columns"
                v-if="
                  billingData.voucherId != null && billingData.voucherId != 0
                "
              >
                <div class="column is-6">
                  <h5 style="margin: 0">Voucher</h5>
                  <p>({{ billingData.voucher.name }})</p>
                </div>
                <div class="column is-6 word-break">
                  <h5 style="margin: 0">Rp. -{{ countVoucher | numeral }}</h5>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">
                  <h5 style="margin: 0">Grand Total</h5>
                </div>
                <div class="column is-6 word-break">
                  <h5 style="color: red; margin: 0">
                    Rp. {{ billingData.price | numeral }}
                  </h5>
                </div>
              </div>
              <hr style="margin: 20px" />
              <div class="columns">
                <div class="column is-6">Payment Method</div>
              </div>
              <div class="columns">
                <div>
                  <img
                    :src="require(`@/assets/images/BCA.png`)"
                    style="width: 60px; height: 20px"
                  />
                  <div
                    style="
                      font-weight: bold;
                      display: inline-block;
                      margin-left: 10px;
                    "
                  >
                    {{ billingData.paymentMethod }}
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">Payment Slip</div>
              </div>
              <div v-if="billingData && billingData.image">
                <img
                  :src="`${getEnv}/static/${billingData.image}`"
                  width="100%"
                  style="cursor: pointer"
                  @click="showModalAdd()"
                />
              </div>

              <hr style="margin: 20px" />
              <div class="columns m-0" v-if="checkPayment">
                <b-button
                  class="columns is-7"
                  type="is-primary"
                  outlined
                  label="Reject"
                  @click="showDialogRejectPayment(billingData.id)"
                />
                <b-button
                  class="columns is-5"
                  label="Approve"
                  @click="showDialogConfirmPayment(billingData.id)"
                />
              </div>
            </div>

            <b-loading
              :is-full-page="false"
              v-model="isLoadingQuickView"
              :can-cancel="true"
            ></b-loading>
          </div>
        </span>
      </b-sidebar>
      <b-modal v-model="isCardModalActive" :width="700" scroll="clip">
        <div class="card" style="height: 600px">
          <div v-if="billingData && billingData.image" class="card-content">
            <img :src="`${getEnv}/static/${billingData.image}`" width="100%" />
          </div>
        </div>
      </b-modal>
    </template>
  </r-page>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'Payments',
  data() {
    return {
      billingDatas: [],
      meta: {},
      isLoadingQuickView: false,
      billingData: null,
      isLoading: false,
      isPrimary: true,
      isOpen: false,
      isCardModalActive: false,
      paymentParams: {
        perPage: 10,
        page: 1,
        search: '',
        orderBy: 'created_at',
        orderDirection: 'desc',
      },
    }
  },
  created() {
    this.getData()
  },
  computed: {
    sideBarStyle() {
      if (this.isOpen) {
        return `border: 1px solid #346BA1; margin-top: 20px margin-left: 20px; border-radius: 5px; padding: 5px;`
      }
      return 'margin-top: 20px;'
    },
    getEnv() {
      console.log(process.env)
      return process.env.VUE_APP_API_URL
    },
    checkPayment() {
      if (this.billingData.status == 'unpaid') {
        return true
      }
      return false
    },
    countVoucher() {
      if (this.billingData != null) {
        let diskon = 0
        if (this.billingData.voucher.status == 'percentage') {
          diskon =
            (this.billingData.voucher.value / 100) *
            (this.billingData.package.price - this.billingData.prorate)
        } else if (this.billingData.voucher.status == 'nominal') {
          diskon = this.billingData.voucher.value
        }

        return diskon
      }

      return 0
    },
  },

  methods: {
    ...mapActions({
      getAllBillingsData: 'payments/getAllBillings',
      approvePayment: 'payments/approvePayment',
      rejectPayment: 'payments/rejectPayment',
    }),
    paymentStatus(status) {
      switch (status) {
        case 'unpaid':
          return 'is-danger'
        case 'paid':
          return 'is-success'
        case 'rejected':
          return 'is-warning'
        case 'past':
          return 'is-info'
        default:
          break
      }
    },
    async getData() {
      this.isLoading = true
      let data = await this.getAllBillingsData(this.paymentParams)
      this.isLoading = false
      this.billingDatas = data.data
      this.meta = data.meta
      console.log('get data:', data.meta)
      //console.log(data)
    },
    showModalAdd() {
      this.isCardModalActive = true
    },
    formatDate(date) {
      let dateNow = moment(date).format('DD-MM-YYYY')
      var nextMonth = moment(dateNow, 'DD-MM-YYYY')
        .add(1, 'months')
        .format('MMMM')
      return nextMonth
    },
    showDialogConfirmPayment(paymentID) {
      this.$buefy.dialog.confirm({
        title: 'Confirm Payment',
        message:
          'Are you sure you want to <b>approve</b> this payment? This action cannot be undone.',
        confirmText: 'Approve Payment',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          console.log('test payment id')
          console.log(paymentID)
          await this.paymentApproval(paymentID)
          this.$buefy.toast.open('change status to paid!')
          this.isOpen = false
        },
      })
    },
    showDialogRejectPayment(paymentID) {
      this.$buefy.dialog.confirm({
        title: 'Reject Payment',
        message:
          'Are you sure you want to <b>reject</b> this payment? This action cannot be undone.',
        confirmText: 'Reject Payment',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          console.log('test payment id')
          console.log(paymentID)
          await this.paymentRejection(paymentID)
          this.$buefy.toast.open('change status to rejected!')
          this.isOpen = false
        },
      })
    },
    onPageChange(page) {
      this.paymentParams.page = page
      this.getData()
    },
    async paymentApproval(paymentID) {
      await this.approvePayment(paymentID) //belum selesai
      this.getData() // manggil data
    },
    async paymentRejection(paymentID) {
      await this.rejectPayment(paymentID) //belum selesai
      this.getData() // manggil data
    },
    async quickView(row, columnIndex) {
      console.log('column index:', columnIndex)

      // if (columnIndex != 6 && columnIndex != 3) {
      this.isLoadingQuickView = true
      this.isOpen = true
      await this.$store
        .dispatch('payments/getPayment', { id: row.id })
        .then((success) => {
          this.billingData = success.data
        })
      this.isLoadingQuickView = false
      // }
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    numeral: function (val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },
    billingIssue: function (date) {
      let dateNow = moment(date).format('DD-MM-YYYY')
      var nextMonth = moment(dateNow, 'DD-MM-YYYY')
        .add(1, 'months')
        .format('MMMM')
      return nextMonth
    },
  },
}
</script>
